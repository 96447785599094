import './ui/leftpanel.js';
import './ui/rightpanel.js';
import { supabase } from './supabaseClient.js';

//getUser();

const audio = new Audio('alert.mp3');

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', (event) => {
    var notifcations_enabled = document.getElementById('notications-on').checkVisibility();

    if (event.data && event.data.type === 'FIEF_TICKED' && notifcations_enabled) {
      // Now call the DOM-based functions:
      startTitleBlink();
      startFaviconBlink();
      playNotificationSound();
    }
  });
}

export function panelShowInfo(panel, type) {
  if (type === 'province') {
    panel.querySelector('#right-panel-province').style.display = 'block';
    panel.querySelector('#right-panel-settlement').style.display = 'none';
  } else if (type === 'settlement') {
    panel.querySelector('#right-panel-province').style.display = 'none';
    panel.querySelector('#right-panel-settlement').style.display = 'block';
  }
}

// --------------------------------------------------
// LOGOUT
// --------------------------------------------------
async function logout() {
  await supabase.auth.signOut();
  window.currentUser = null;
  window.location.href = '/login.html';
}
window.logout = logout; // Attach the logout function to global

// --------------------------------------------------
// CHECKBOXES
// --------------------------------------------------
const settlementCheckbox = document.getElementById('settlement-checkbox');
const armyCheckbox = document.getElementById('army-checkbox');

// Toggle settlement layer visibility
settlementCheckbox.addEventListener('change', (event) => {
  window.map.settlementsLayer.setVisible(event.target.checked);
});

// Toggle army layer visibility
armyCheckbox.addEventListener('change', (event) => {
  window.map.toggleArmyLayers(event.target.checked); // Toggle all army layers
});

// --------------------------------------------------
// NOTIFICATIONS
// --------------------------------------------------
function requestNotificationPermission() {
  if ('Notification' in window && Notification.permission !== 'granted') {
    Notification.requestPermission();
  }
}

function showBrowserNotification(title, body) {
  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
      new Notification(title, { body });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          new Notification(title, { body });
        }
      });
    }
  }
}

// --------------------------------------------------
// BLINKING TAB TITLE & FAVICON
// --------------------------------------------------

// Store references to original state
const originalTitle = 'Fief';

// In many browsers/sites, the `<link rel="icon" ...>` might look like:
// <link rel="icon" href="/assets/images/favicon.png">
//
// We'll grab that href so we can revert to it later.
const faviconElement = document.querySelector("link[rel='icon']");
const originalFaviconUrl = faviconElement ? faviconElement.href : '';

// We want an “alert” icon to toggle with. 
// This can be any URL, or a data URL you generate.
const alertFaviconUrl = 'favicon_alert.png'; 
// For demo: a red 64x64 placeholder icon with text "!!!"

// Intervals
let blinkTitleInterval = null;
let blinkFaviconInterval = null;

// Title blinking
function startTitleBlink() {
  if (blinkTitleInterval) return; // Avoid multiple intervals

  let showingOriginal = true;
  blinkTitleInterval = setInterval(() => {
    document.title = showingOriginal ? 'Countdown Reached!' : originalTitle;
    showingOriginal = !showingOriginal;
  }, 1000);
}

function stopTitleBlink() {
  if (blinkTitleInterval) {
    clearInterval(blinkTitleInterval);
    blinkTitleInterval = null;
  }
  // Reset title
  document.title = originalTitle;
}

// Favicon blinking
function setFavicon(url) {
  // Helper to update <link rel="icon" ...>
  if (!faviconElement) return;

  faviconElement.href = url;
}

function startFaviconBlink() {
  if (blinkFaviconInterval) return; // Avoid multiple intervals

  let showingOriginal = true;
  blinkFaviconInterval = setInterval(() => {
    if (showingOriginal) {
      setFavicon(alertFaviconUrl);
    } else {
      setFavicon(originalFaviconUrl);
    }
    showingOriginal = !showingOriginal;
  }, 1000);
}

function stopFaviconBlink() {
  if (blinkFaviconInterval) {
    clearInterval(blinkFaviconInterval);
    blinkFaviconInterval = null;
  }
  // Reset favicon
  setFavicon(originalFaviconUrl);
}

// Listen for when the user focuses this tab/window
window.addEventListener('focus', () => {
  stopTitleBlink();
  stopFaviconBlink();
});

function playNotificationSound() {
  // Attempt to play it
  audio.play().catch((err) => {
    // If there's an error (e.g. user hasn't interacted), 
    // you can handle or log it here
    console.error('Audio play failed:', err);
  });
}

// --------------------------------------------------
// CLOCK CODE
// --------------------------------------------------

function getNextThirdHourTime() {
  const now = new Date();
  const currentHourUTC = now.getUTCHours();

  // Calculate the next 3rd hour in UTC
  let nextThirdHourUTC = currentHourUTC + (3 - (currentHourUTC % 3));
  if (currentHourUTC % 3 === 0 && now.getUTCMinutes() === 0 && now.getUTCSeconds() === 0) {
    nextThirdHourUTC = currentHourUTC;
  }

  // Handle rollover to the next day
  if (nextThirdHourUTC >= 24) {
    nextThirdHourUTC = nextThirdHourUTC % 24;
  }

  // If the next 3rd hour is <= current hour, add a day
  const addDay = nextThirdHourUTC <= currentHourUTC ? 1 : 0;

  // Create the next tick time in UTC
  const nextTick = new Date(Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate() + addDay,
    nextThirdHourUTC,
    0,
    0,
    0
  ));

  return nextTick;
}

function updateClock() {
  const now = new Date();
  let nowUTC = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds()
  );

  const nextTick = getNextThirdHourTime();
  let timeRemaining = nextTick.getTime() - nowUTC; // in ms

  // If timeRemaining is negative, add 24 hrs (edge case)
  if (timeRemaining < 0) {
    timeRemaining += 24 * 3600 * 1000;
  }

  const totalSeconds = Math.floor(timeRemaining / 1000);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Format as HH:MM:SS
  const timeString = `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;

  // Update the clock on the page
  const clockElement = document.getElementById('clock-time');
  if (clockElement) {
    clockElement.textContent = timeString;

    // // If countdown hits 00:00:01
    // if (timeString === '00:00:00') {
    //   startTitleBlink();
    //   startFaviconBlink();
    //   playNotificationSound();
    // }
  }

}

function padZero(num) {
  return num < 10 ? '0' + num : num;
}

// Update every second
setInterval(updateClock, 1000);
updateClock();

// Optionally request permission on load
requestNotificationPermission();


// --------------------------------------------------
// USER AUTH CODE (unchanged)
// --------------------------------------------------
export async function getUser() {
  const {
    data: { user },
    error,
  } = await supabase.auth.getUser();

  if (user) {
    document.getElementById('user-display').textContent = user.user_metadata.display_name;
    document.querySelector('.login-button').style.display = 'none';
    document.querySelector('.signup-button').style.display = 'none';
    window.currentUser = user;
    return user;
  } else {
    document.querySelector('.logout-button').style.display = 'none';
    window.currentUser = null;
  }
}
