import Feature from 'ol/Feature.js';
import { LineString, Point } from 'ol/geom.js';
import { Style, Fill, Stroke, RegularShape } from 'ol/style.js';

function getCoordinates(mapObj, locationId, locationType, layer, settlements) {
  if (locationType === 'province') {
    const feature = mapObj[layer].getSource().getFeatures().find(f => f.get('id') == locationId);
    return feature ? feature.getGeometry().getInteriorPoint().getCoordinates() : null;
  } else if (locationType === 'settlement') {
    const settlement = settlements[locationId];
    if (!settlement) return null;
    const feature = mapObj[layer].getSource().getFeatureById(settlement.id);
    return feature ? feature.getGeometry().getCoordinates() : null;
  }
  return null;
}

function createArrowStyle() {
  return (feature) => {
    const geometry = feature.getGeometry();
    const styles = [
      new Style({
        stroke: new Stroke({
          color: '#2e2e2e',
          width: 5,
        }),
      }),
    ];

    const coordinates = geometry.getCoordinates();
    if (coordinates.length > 1) {
      const end = coordinates[coordinates.length - 1];
      const start = coordinates[coordinates.length - 2];
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);

      styles.push(new Style({
        geometry: new Point(end),
        image: new RegularShape({
          fill: new Fill({ color: '#2e2e2e' }),
          points: 3,
          radius: 10,
          rotation: -rotation + Math.PI / 2,
          angle: 0,
        }),
      }));
    }
    return styles;
  };
}

export function drawMovementArrow(mapObj, army, selectedDestination = null) {
  const destination = selectedDestination || { id: army.destination, type: army.destination_type };

  if (!destination?.id || !destination?.type) {
    return;
  }

  const startCoord = getCoordinates(mapObj, army.location, army.location_type, 'provincesLayer', mapObj.settlements) ||
                     getCoordinates(mapObj, army.location, army.location_type, 'settlementsLayer', mapObj.settlements);

  const endCoord = getCoordinates(mapObj, destination.id, destination.type, 'provincesLayer', mapObj.settlements) ||
                   getCoordinates(mapObj, destination.id, destination.type, 'settlementsLayer', mapObj.settlements);

  if (!startCoord || !endCoord) {
    return;
  }

  const line = new Feature({
    geometry: new LineString([]),
  });
  line.set('army_id', army.id);
  line.setStyle(createArrowStyle());
  mapObj.movementArrowsLayer.getSource().addFeature(line);

  //Animate it
  const lineCoords = [startCoord];
  const numSteps = 5;
  let currentStep = 0;

  const interval = setInterval(() => {
    currentStep++;
    const t = currentStep / numSteps;
    const interpolatedCoord = [
      startCoord[0] + t * (endCoord[0] - startCoord[0]),
      startCoord[1] + t * (endCoord[1] - startCoord[1]),
    ];
    lineCoords.push(interpolatedCoord);
    line.getGeometry().setCoordinates(lineCoords);
    if (currentStep >= numSteps) {
      clearInterval(interval);
    }
  }, 20);
}