import Feature from 'ol/Feature.js';
import { drawMovementArrow } from './movement_arrows.js';
import { supabase } from '../../supabaseClient.js';
import { hideRecruitPopup } from '../../ui/recruit_popup.js';

window.map.mergeMode = false;
var mergeArmiesList = new Set();

var clusterPopupMergeBtn = document.getElementById('cluster-popup-merge');
const clusterPopupSplitBtn = document.getElementById('cluster-popup-split');
const clusterPopupDisbandBtn = document.getElementById('cluster-popup-disband');

const headerButtons = document.querySelector('.cluster-popup-header-buttons');
const splitButton = document.getElementById('cluster-popup-split');
const mergeButton = document.getElementById('cluster-popup-merge');
var loader = document.getElementById('loader');

// Function to call the API (replace with your actual API call function)
async function callSplitArmyApi(army_id, splitSize) {
    const { data: { session } } = await supabase.auth.getSession();
    const accessToken = session.access_token;

    console.log("entering splitArmy function");
  
    fetch('/api/split_army', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({ army_id: army_id, split_size: splitSize })
    })
    .then(response => response.json())
    .then(async data => {
      if (data.message == 'success') {
        console.log("Army split successfully");
  
        await window.map.reLoadData();
        loader.remove();
        splitButton.style.display = 'block';
        window.map.resetSelectedCluster();
      } else {
        console.error("Error splitting army:", data.error);
        loader.remove();
        splitButton.style.display = 'block';
        window.map.resetSelectedCluster();
        await window.map.reLoadData();
      }
    });
}

export function onClusterArmyItemClick(mapObj, listItem, clusterFeature = null, armies=null) {
    const clickedArmyId = listItem.getAttribute('data-army-id');
    const clickedUserId = listItem.getAttribute('data-army-user-id');
  
    let currentClusterFeature = clusterFeature;
  
    // If no cluster feature was passed, try to find it
    if (!currentClusterFeature && mapObj.mapSelectedArmyId) {
      currentClusterFeature = findClusterFeatureBySelectedArmy(mapObj);
    }
  
    // If we still don’t have a cluster feature, see if we can locate it:
    // (e.g. an army feature in the cluster that matches the clickedArmyId)
    if (!currentClusterFeature) {
      currentClusterFeature = findClusterFeatureByArmyId(mapObj, clickedArmyId);
    }
  
    if (currentClusterFeature) {
      console.log('Cluster feature found:', currentClusterFeature);
    } else {
      console.log('No cluster feature found for the clicked army. Proceeding without cluster...');
      // If you have logic that strictly requires a clusterFeature, you can return or skip
      // return;
    }
  
    if (clickedUserId !== window.currentUser.id) {
      console.log('Clicked someone else’s army, ignoring...');
      return;
    }
  
    if (window.map.mergeMode) {
      handleMergeModeArmyClick(mapObj, listItem, clickedArmyId, clickedUserId);
    } else if (clusterPopupSplitBtn.dataset.splitMode === 'active') {
      // The checkmark handles the click
    }
    else {
      // If no clusterFeature is found, you might skip or do partial logic
      if( currentClusterFeature ) {
        handleCurrentUserArmyClick(mapObj, clickedArmyId, currentClusterFeature, null);
      }
      else {
        handleCurrentUserArmyClick(mapObj, clickedArmyId, null, armies);
      }
      
    }
}

function findClusterFeatureByArmyId(mapObj, armyId) {
    return mapObj.armyLayers.flatMap(layer => layer.getSource().getFeatures())
      .find(f => {
        if (!f.get('features')) return false;
        return f.get('features').some(subF => subF.get('army_id') == armyId);
      });
}

function findClusterFeatureBySelectedArmy(mapObj) {
    return mapObj.armyLayers.flatMap(layer => layer.getSource().getFeatures())
      .find(f => f.get('features') && f.get('features')
        .some(f2 => f2.get('army_id') == mapObj.mapSelectedArmyId));
}

function handleCurrentUserArmyClick(mapObj, clickedArmyId, clusterFeature, armies=null) {
    if (!clusterFeature) {
      // If there is no cluster feature, handle minimal logic or skip
      console.warn('No cluster feature to handleCurrentUserArmyClick. Skipping cluster-based logic.');
      // You might still do:
      mapObj.mapSelectedArmyId = clickedArmyId;
      mapObj.movementArrowsLayer.getSource().clear();
      drawMovementArrow(mapObj, mapObj.armies[clickedArmyId]);
      updateMapSelectionAndArrow(mapObj, clickedArmyId);
      redrawClusterPopup(mapObj, armies);
      return;
    }
  
    const userArmiesInCluster = getUserArmiesInCluster(clusterFeature);
    const clickedArmyIndex = findClickedArmyIndex(userArmiesInCluster, clickedArmyId);
  
    if (clickedArmyIndex === -1) {
      console.warn('Could not find user army with ID', clickedArmyId, 'in the cluster.');
      return;
    }
  
    updateMapSelectionAndArrow(mapObj, clickedArmyId);
    updateClusterFeatureSelection(clusterFeature, clickedArmyIndex);
    redrawClusterPopup(mapObj, clusterFeature);
}

function handleMergeModeArmyClick(mapObj, listItem, clickedArmyId, clickedUserId) {

    var army = mapObj.armies[clickedArmyId];

    if (clickedUserId !== window.currentUser.id) {
        console.log('Clicked someone else’s army, ignoring...');
        return;
    }
    else {
        if (listItem.classList.contains('selected')) {
            listItem.classList.remove('selected');
            mergeArmiesList.delete(army);
        }
        else {
            mergeArmiesList.add(army);
            listItem.classList.add('selected');
        }
    }
}

function getUserArmiesInCluster(clusterFeature) {
    const featuresInCluster = clusterFeature.get('features') || [];
    return featuresInCluster.filter(f => f.get('user_id') === window.currentUser.id);
}

function findClickedArmyIndex(userArmies, clickedArmyId) {
    return userArmies.findIndex(f => f.get('army_id') == clickedArmyId);
}

function updateMapSelectionAndArrow(mapObj, selectedArmyId) {
    mapObj.mapSelectedArmyId = selectedArmyId;
    mapObj.movementArrowsLayer.getSource().clear();
    drawMovementArrow(mapObj, mapObj.armies[selectedArmyId]);
}

function updateClusterFeatureSelection(clusterFeature, index) {
    clusterFeature.set('selectedUserArmyIndex', index);
    clusterFeature.changed();
}

function redrawClusterPopup(mapObj, clusterFeature) {
    const overlayPos = mapObj.clusterOverlay.getPosition();
    showClusterPopup(mapObj, clusterFeature, overlayPos);
}

export function showClusterPopup(mapObj, featureOrArmies, coordinate) {
    let armies = [];
    let clusterFeature = null;

    hideRecruitPopup();
  
    // 1) Determine if the second argument is an array or a cluster feature
    if (Array.isArray(featureOrArmies)) {
      // We received a pure list of armies
      armies = featureOrArmies;
      // Possibly pass a custom coordinate if you want the popup to appear somewhere
    } else if (featureOrArmies && featureOrArmies.get) {
      // We received a cluster Feature
      clusterFeature = featureOrArmies;
      const featuresInCluster = clusterFeature.get('features') || [];
      // Convert OL features -> our army objects
      armies = featuresInCluster.map(f => window.map.armies[f.get('army_id')]);
    } else {
      console.warn('showClusterPopup called without valid armies or feature.');
      return;
    }
  
    // 2) If no coordinate is given and we do have a clusterFeature, read it from geometry
    if (!coordinate && clusterFeature) {
      coordinate = clusterFeature.getGeometry().getCoordinates();
    }
  
    // 3) Determine which army is “selected”
    // (If we have a clusterFeature with a “selectedUserArmyIndex”, use it. 
    //  Otherwise if we have an array, pick your own logic.)
    let selectedArmyId = null;
    if (clusterFeature) {
      // Old logic: figure out selected user army from index on feature
      const featuresInCluster = clusterFeature.get('features') || [];
      const userArmies = featuresInCluster.filter(f => f.get('user_id') === window.currentUser.id);
      const selectedIndex = clusterFeature.get('selectedUserArmyIndex');
      selectedArmyId = determineSelectedArmyId(mapObj, userArmies, selectedIndex);
    } else {
      // For armies array mode, you can define your own approach:
      // e.g. pick the first if there is one
      if (armies.length > 0) {
        selectedArmyId = window.map.mapSelectedArmyId || armies[0].id;
      }
    }
  
    // 4) If we found a selectedArmyId, set it
    if (selectedArmyId) {
      updateMapSelectionAndArrow(mapObj, selectedArmyId);
    }
  
    // 5) Generate HTML for the armies
    const html = generateClusterPopupContentFromArmies(armies, selectedArmyId);
    mapObj.clusterPopupContent.innerHTML = html;
  
    // 6) Attach item-click listeners
    //    We pass the clusterFeature (which might be null) so that the 
    //    click function can do fallback if needed
    attachPopupItemClickListeners(mapObj, clusterFeature, armies);
  
    // 7) Show the popup
    mapObj.clusterPopupContainer.classList.remove('hidden');
    if (coordinate) {
      mapObj.clusterOverlay.setPosition(coordinate);
    }
}

function generateClusterPopupContentFromArmies(armies, selectedArmyId) {
    let html = '';
    for (const army of armies) {
      const userId = army.user_id;
      const size = army.size || 0;
      const armyId = army.id;
      const allegiance = army.allegiance;
      const destination = army.destination;
      const liClass = (armyId == selectedArmyId) ? 'selected' : '';
      let moving_html = '';
      let move_button_html = '';
  
      if (destination) {
        moving_html = `
          <div class="moving-icon">
            <img src="moving.png" alt="Move">
          </div>
        `;
      }

      if (army.user_id == window.currentUser.id) {
        console.log('Adding move button');
        move_button_html = `
          <button class="move-button">
            <img src="move.png" alt="Move">
          </button>
        `
      }
  
      html += `
        <li
          class="${liClass}"
          data-army-id="${armyId}"
          data-army-user-id="${userId}">
          ${allegiance === 'settlement'
            ? `${size}K Infantry - Settlement`
            : `${size}K Infantry - Nation`
          }
          ${move_button_html}
          ${moving_html}
        </li>
      `;
    }
    return '<ul>' + html + '</ul>';
}

function determineSelectedArmyId(mapObj, userArmies, selectedIndex) {
    if (userArmies.length && selectedIndex !== null && selectedIndex !== undefined) {
        const actualIndex = Math.min(selectedIndex, userArmies.length - 1);
        return userArmies[actualIndex].get('army_id');
    }
    return null;
}

export function generateClusterPopupContent(featuresInCluster, selectedArmyId) {
    let html = '';
    let moving_html = '';
    let move_button_html = '';
    for (const armyFeature of featuresInCluster) {
        moving_html = '';

        const army = window.map.armies[armyFeature.get('army_id')];

        const userId = army.user_id;
        const size = army.size || 0;
        const armyId = army.id;
        const allegiance = army.allegiance;
        const liClass = armyId === selectedArmyId ? 'selected' : '';
        const destination = army.destination;

        if (destination) {
            moving_html = `
            <div class="moving-icon">
              <img src="moving.png" alt="Move">
            </div>
          `
        }

        if (army.user_id == window.currentUser.id) {
          console.log('Adding move button');
          move_button_html = `
            <button class="move-button">
              <img src="move.png" alt="Move">
            </button>
          `
        }

        html += `
            <li
                class="${liClass}"
                data-army-id="${armyId}"
                data-army-user-id="${userId}">
                ${allegiance == 'settlement'
                    ? `${size}K Infantry - Settlement`
                    : `${size}K Infantry - Nation`}
                ${move_button_html}
                ${moving_html}
            </li>
        `;
    }
    return '<ul>' + html + '</ul>';
}

function attachPopupItemClickListeners(mapObj, clusterFeature, armies) {
    const listItems = mapObj.clusterPopupContent.querySelectorAll('li');
    listItems.forEach(li => {
      li.addEventListener('click', () => {
        // Pass clusterFeature (possibly null) so that onClusterArmyItemClick can fallback
        onClusterArmyItemClick(mapObj, li, clusterFeature, armies);
      });
  
      const moveButton = li.querySelector('.move-button');
      if (moveButton) {
        moveButton.addEventListener('click', (evt) => {
          evt.stopPropagation();
          handleMoveButtonClick(mapObj, li);
        });
      }
    });
  }

function handleMoveButtonClick(mapObj, listItem) {
    const selectedArmyId = listItem.getAttribute('data-army-id');
    const selectedArmy = mapObj.armies[selectedArmyId];

    mapObj.movementArrowsLayer.getSource().clear();

    if (selectedArmy && selectedArmy.user_id == window.currentUser.id) {
        mapObj.enterMoveMode(selectedArmy);
        mapObj.clusterPopupContainer.classList.add('hidden');
        mapObj.clusterOverlay.setPosition(undefined);
    }
}

export async function mergeArmies(armies, mapObj, clusterFeature) {
    const armyIds = Array.from(armies).map(army => army.id);
    const { data: { session } } = await supabase.auth.getSession();
    const accessToken = session.access_token;
  
    if (armyIds.length > 1) {
      fetch('/api/merge_armies', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ army_ids: armyIds })
      })
        .then(response => response.json())
        .then(async data => {
          if (data.message == 'success') {
            console.log("Army merged successfully");
  
            await window.map.reLoadData();
            loader.remove();
            mergeButton.style.display = 'block';
            await window.map.resetSelectedCluster();       
          }
          else {
            console.error("Error merging armies:", data.error);
            loader.remove();
            mergeButton.style.display = 'block';
            await window.map.resetSelectedCluster();
            await window.map.reLoadData();
          }
        });
    }
  }

async function ClusterMergeClick() {

    const listItems = window.map.clusterPopupContent.querySelectorAll('li');

    if (!window.map.mergeMode) {

        //deselect the selected army list item
        window.map.mapSelectedArmyId = null;
        window.map.movementArrowsLayer.getSource().clear();

        mergeArmiesList = new Set();

        document.getElementById('cluster-merge-icon').src = 'check_icon.png';



        listItems.forEach(li => {
            console.log(li);
            li.classList.remove('selected');
        });

        listItems.forEach(li => {
            console.log(li);
            li.classList.add('merge-list-item');
        });

        window.map.mergeMode = true;
    }
    else {
        if (mergeArmiesList.size > 1) {
            console.log('Merging armies:', mergeArmiesList);
            await mergeArmies(mergeArmiesList, window.map);
            mergeArmiesList = new Set();
            window.map.mergeMode = false;

            document.getElementById('cluster-merge-icon').src = 'merge.png';

            // Create and insert number input
            loader = document.createElement('div');
            loader.id = 'loader';

            loader.classList.add('loader');

            headerButtons.insertBefore(loader, splitButton);

            mergeButton.style.display = 'none';

        }
        else {
            console.log('Select at least two armies to merge');
        }
    }

}

async function handleSplitButtonClick() {

    if (splitButton.dataset.splitMode !== 'active') {
        // Change icon to checkmark
        splitButton.innerHTML = '<img src="check_icon.png" alt="Split">';
        splitButton.dataset.splitMode = 'active';

        // Create and insert number input
        const numberInput = document.createElement('input');
        numberInput.type = 'number';
        numberInput.id = 'split-amount-input';
        numberInput.style.width = '30px'; // Adjust as needed
        numberInput.style.height = '12px';
        numberInput.style.marginRight = '5px';
        numberInput.style.marginBottom = '2.5px';

        headerButtons.insertBefore(numberInput, splitButton);
        
    } else {
        // Handle checkmark click
        const numberInput = document.getElementById('split-amount-input');
        const amount = parseInt(numberInput.value, 10);
        const selectedArmyId = window.map.mapSelectedArmyId;

        if (selectedArmyId && amount) {
            console.log(selectedArmyId, amount);
            await callSplitArmyApi(selectedArmyId, amount);
        } else {
            console.warn('Please select an army and enter a valid amount to split.');
        }

        // Revert button to split icon
        splitButton.innerHTML = '<img src="split.png" alt="Split">';
        delete splitButton.dataset.splitMode;

        // Remove the number input
        numberInput.remove();

        // Create and insert number input
        loader = document.createElement('div');
        loader.id = 'loader';

        loader.classList.add('loader');

        headerButtons.insertBefore(loader, splitButton);

        splitButton.style.display = 'none';
    }
}

async function handleDisbandButtonClick() {

    const selectedArmyId = window.map.mapSelectedArmyId;

    var army = window.map.armies[selectedArmyId];
    window.map.movementArrowsLayer.getSource().clear();
    await army.disband();
    window.map.resetSelectedCluster();

}

clusterPopupMergeBtn.onclick = ClusterMergeClick.bind();
clusterPopupSplitBtn.onclick = handleSplitButtonClick;
clusterPopupDisbandBtn.onclick = handleDisbandButtonClick;