import { Style, Fill, Stroke, Text } from 'ol/style.js';
import Feature from 'ol/Feature.js';
import { Point } from 'ol/geom.js';

const calculateNationLabelFontSize = (zoom, nameLength) => {
  let fontSize;
  if (zoom >= 8) {
    fontSize = zoom >= 10 ? 20 : 20;
  } else if (zoom >= 4) {
    fontSize = zoom >= 6 ? 16 : 20;
  } else {
    fontSize = 15;
  }
  return nameLength > 10 ? fontSize * 0.8 : fontSize;
};

const createNationLabelStyle = (feature, fontSize) => {
  return new Style({
    text: new Text({
      text: feature.get('name'),
      font: `bold ${fontSize}px "Cinzel", serif`,
      fill: new Fill({ color: '#fff' }),
      stroke: new Stroke({ color: '#000', width: 2 }),
      placement: 'point',
      textAlign: 'center',
    }),
  });
};

export const getNationLabelStyle = (mapObj, feature, resolution) => {
  const zoom = mapObj.map.getView().getZoom();
  const fontSize = calculateNationLabelFontSize(zoom, feature.get('name').length);
  return createNationLabelStyle(feature, fontSize);
};

const groupProvincesByNation = (provinces) => {
  return Object.values(provinces).reduce((acc, province) => {
    if (province.nation_id) {
      acc[province.nation_id] = acc[province.nation_id] || {
        name: province.nation_name,
        provinces: [],
      };
      acc[province.nation_id].provinces.push(province);
    }
    return acc;
  }, {});
};

const calculateNationCentroid = (provinceCentroids) => {
  const numCentroids = provinceCentroids.length;
  if (numCentroids === 0) return null;
  const sumX = provinceCentroids.reduce((sum, coord) => sum + coord[0], 0);
  const sumY = provinceCentroids.reduce((sum, coord) => sum + coord[1], 0);
  return [sumX / numCentroids, sumY / numCentroids];
};

const findCenterMostProvince = (nation, nationCentroid, provincesLayerSource) => {
  let minDistanceSq = Infinity;
  let centerMostProvince = null;

  for (const province of nation.provinces) {
    const feature = provincesLayerSource
      .getFeatures()
      .find((f) => f.get('id') == province.id);
    if (feature) {
      const centroid = feature.getGeometry().getInteriorPoint().getCoordinates();
      const dx = centroid[0] - nationCentroid[0];
      const dy = centroid[1] - nationCentroid[1];
      const distanceSq = dx * dx + dy * dy;

      if (distanceSq < minDistanceSq) {
        minDistanceSq = distanceSq;
        centerMostProvince = feature;
      }
    }
  }
  return centerMostProvince;
};

const createNationLabelFeature = (nation, centerMostProvince) => {
  if (!centerMostProvince) return null;
  const labelPointCoord = centerMostProvince.getGeometry().getInteriorPoint().getCoordinates();
  return new Feature({
    geometry: new Point(labelPointCoord),
    name: nation.name,
  });
};

export const updateNationLabels = (mapObj) => {
  mapObj.nationLabelsSource.clear();
  const nations = groupProvincesByNation(mapObj.provinces);
  const provincesLayerSource = mapObj.provincesLayer.getSource();

  for (const nationId in nations) {
    const nation = nations[nationId];
    const provinceCentroids = nation.provinces.map(province => {
      const feature = provincesLayerSource.getFeatures().find(f => f.get('id') == province.id);
      return feature ? feature.getGeometry().getInteriorPoint().getCoordinates() : null;
    }).filter(Boolean);

    const nationCentroid = calculateNationCentroid(provinceCentroids);
    if (!nationCentroid) continue;

    const centerMostProvince = findCenterMostProvince(nation, nationCentroid, provincesLayerSource);
    const labelFeature = createNationLabelFeature(nation, centerMostProvince);

    if (labelFeature) {
      mapObj.nationLabelsSource.addFeature(labelFeature);
    }
  }
  mapObj.map.render();
};