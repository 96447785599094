import { supabase } from '../supabaseClient.js';

const settlementInfoPopup = document.getElementById('settlement-info-popup');
const settlementInfoPopupClose = document.getElementById('settlement-info-popup-close');
const settlementInfoName = document.getElementById('settlement-info-name');
const settlementInfoOwner = document.getElementById('settlement-info-owner');

// Economy tab elements
const settlementEconomyMoneySettlement = document.getElementById('settlement-economy-money-loyal-settlements');
const settlementEconomyMoneyMaintenance = document.getElementById('settlement-economy-money-maintenance');
const settlementEconomyMoneyNet = document.getElementById('settlement-economy-money-net');
const settlementEconomyManpowerSettlement = document.getElementById('settlement-economy-manpower-loyal-settlements');
const settlementEconomyManpowerNet = document.getElementById('settlement-economy-manpower-net');

const infoPopupTabs = document.querySelectorAll('.info-popup-tab');
const infoPopupTabContents = document.querySelectorAll('.info-popup-tab-content');

const loyaltyStatus = document.getElementById('rp-settlement-loyalty');
const loyaltyToggle = document.getElementById('rp-settlement-loyalty-toggle');
const loyaltyTextElement = document.getElementById('rp-settlement-loyalty-text');

settlementInfoPopupClose.addEventListener('click', closeSettlementInfoPopup);

infoPopupTabs.forEach(tab => {
    tab.addEventListener('click', async () => {
        const target = tab.dataset.tab;

        // Deactivate all tabs and content
        infoPopupTabs.forEach(t => t.classList.remove('active'));
        infoPopupTabContents.forEach(content => content.classList.remove('active'));

        // Activate the clicked tab
        tab.classList.add('active');

        // Activate the corresponding content
        const targetContent = document.getElementById(target);
        if (targetContent) {
            targetContent.classList.add('active');

            // Fetch economy data ONLY if the Economy tab is clicked and data hasn't been fetched yet
            if (target === 'settlement-info-economy' && settlementInfoPopup.dataset.settlementId && !targetContent.dataset.fetched) {
                await fetchSettlementEconomyData(settlementInfoPopup.dataset.settlementId);
                targetContent.dataset.fetched = 'true'; // Mark data as fetched
            }
        }
    });
});

async function fetchSettlementEconomyData(settlementId) {
  const { data: { session } } = await supabase.auth.getSession();
  const accessToken = session.access_token;

  console.log("Fetching settlement economy data for settlement ID:", settlementId);

    try {
        const response = await fetch('/api/get_income', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ type: 'settlement', id: settlementId })
        });
        const data = await response.json();
        if (data.income) {
      console.log(data.income);
            populateEconomyTab(data.income);
        } else {
            console.error("Error fetching settlement income:", data.error);
            // Handle error appropriately (e.g., display a message)
        }
    } catch (error) {
        console.error("Error fetching settlement income:", error);
        // Handle error appropriately
    }
}

function populateEconomyTab(income) {
    settlementEconomyMoneySettlement.textContent = income.moneyIncome.fromThisSettlement;
    settlementEconomyMoneyMaintenance.textContent = '-' + income.moneyExpenses.armyMaintenance;
    settlementEconomyMoneyNet.textContent = income.netMoneyIncome;
    settlementEconomyManpowerSettlement.textContent = income.manpowerIncome.fromThisSettlement;
    settlementEconomyManpowerNet.textContent = income.netManpowerIncome;
}

export function openSettlementInfoPopup(settlement) {
    console.log('Opening settlement info popup:', settlement);
    settlementInfoPopup.dataset.settlementId = settlement.id; // Store nation ID
    settlementInfoName.textContent = settlement.name;
    settlementInfoOwner.textContent = settlement.user_display_name;

    loyaltyToggle.checked = settlement.loyal;
	  loyaltyTextElement.innerHTML = loyaltyToggle.checked ? 'Loyal' : 'Disloyal';
	
  
	  // Show or hide the loyalty toggle based on ownership
    console.log('Current user:', window.currentUser);
    console.log('Settlement user ID:', settlement.user_id);
    console.log('Settlement nation user ID:', settlement.nation_user_id);
	  if (window.currentUser.id == settlement.user_id && settlement.nation_user_id != window.currentUser.id) {
		  document.querySelector('.settlement-loyalty').style.display = 'flex';
		  // Remove existing event listener
		  loyaltyToggle.removeEventListener('change', loyaltyToggleHandler);
		  // Add event listener for loyalty toggle
		  loyaltyToggle.addEventListener('change', loyaltyToggleHandler);
	  } else {
      console.log('Hiding loyalty toggle');
		  document.querySelector('.settlement-loyalty').style.display = 'none';
	  }

    // Initially activate the 'Information' tab
    infoPopupTabs.forEach(tab => tab.classList.remove('active'));
    infoPopupTabContents.forEach(content => content.classList.remove('active'));
    document.querySelector('.info-popup-tab[data-tab="settlement-info-details"]').classList.add('active');
    const initialContent = document.getElementById('settlement-info-details');
    if (initialContent) {
        initialContent.classList.add('active');
    }

    // Reset the fetched state of the economy tab content
    const economyContent = document.getElementById('settlement-info-economy');
    if (economyContent) {
        economyContent.dataset.fetched = '';
    }

    settlementInfoPopup.classList.remove('hidden');
}

function loyaltyToggleHandler() {
  const newLoyaltyStatus = loyaltyToggle.checked;
  // Update the loyalty status text inside the slider
  loyaltyTextElement.innerHTML = newLoyaltyStatus ? 'Loyal' : 'Disloyal';
  // Send the update to the server
  updateSettlementLoyalty(window.map.mapSelectedSettlementId, newLoyaltyStatus);
}
  

async function updateSettlementLoyalty(settlementId, isLoyal) {
  const { data: { session } } = await supabase.auth.getSession();
  const accessToken = session.access_token;
  
  fetch('/api/update_settlement_loyalty', {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`
    },
    body: JSON.stringify({ settlement_id: settlementId, loyal: isLoyal })
  })
  .then(response => response.json())
  .then(async data => {
    if (data.message == 'success') {
    console.log("Settlement loyalty updated successfully");
  
    } else {
    const newLoyaltyStatus = !loyaltyToggle.checked;

    // Update the loyalty status text inside the slider
    loyaltyTextElement.innerHTML = newLoyaltyStatus ? 'Loyal' : 'Disloyal';
    loyaltyToggle.checked = newLoyaltyStatus;

    console.error("Error updating settlement loyalty:", data.error);
    alert("Error updating settlement loyalty: " + data.error);
    }
  })
  .catch(error => {
    console.error("Error:", error);
    alert("Error updating settlement loyalty: " + error.message);
  });
}

export function closeSettlementInfoPopup() {
    settlementInfoPopup.classList.add('hidden');
}