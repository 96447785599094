import { supabase } from '../supabaseClient.js';
import { loadOutliner } from './outliner.js';


// ADD: a reference to the close button of the recruit popup
const recruitPopupClose = document.getElementById('recruit-popup-close');
var recruitType = null;

recruitPopupClose.onclick = () => {
  hideRecruitPopup();
  return false;
};

const recruitSubmit = document.getElementById('recruit-submit');

recruitSubmit.onclick = () => {
  const amount = parseInt(document.getElementById('recruit-amount').value, 10);

  //if the settlement radial menu is open, recruit to the settlement
  if (recruitType == 'settlement') {
	recruitArmy(window.map.mapSelectedSettlementId, 'settlement', amount);
  }
  else {
	recruitArmy(window.map.mapSelectedProvinceId, 'province', amount);
  }
  
  

  // Then hide popup
  hideRecruitPopup();
};

export function showRecruitPopup(type) {
    // 1) Get the same coordinate used to anchor the province radial menu
	if (type == 'settlement') {
		var coordinate = window.map.settlementRadialMenuOverlay.getPosition();
		recruitType = 'settlement';
	}
	else {
		var coordinate = window.map.provinceRadialMenuOverlay.getPosition();
		recruitType = 'province';
	}
  
    // 2) Position the recruit popup overlay at that coordinate
    window.map.recruitPopupOverlay.setPosition(coordinate);
  
    // 3) Make sure it’s visible
    const recruitPopupEl = document.getElementById('recruit-popup');
    recruitPopupEl.classList.remove('hidden');

	//hide cluster popup
	window.map.onClusterPopupClose();
}

export function hideRecruitPopup() {
	const recruitPopupEl = document.getElementById('recruit-popup');
	recruitPopupEl.classList.add('hidden');
	window.map.recruitPopupOverlay.setPosition(undefined);
}

async function recruitArmy(locationId, locationType, recruitNumber) {
	console.log("Recruit army function called");
	const { data: { session } } = await supabase.auth.getSession();
	const accessToken = session.access_token;
	fetch('/api/recruit_army', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${accessToken}`
		},
		body: JSON.stringify({ location_id: locationId, location_type: locationType, recruit_size: recruitNumber })
	})
	.then(response => response.json())
	.then(async data => {
		if (data.message == 'success') {
			console.log("Army recruited successfully");

			window.map.reLoadData();
			
		} else {
			console.error("Error recruiting army:", data.error);
			alert("Error recruiting army: " + data.error);
		}
	})
	.catch(error => {
		console.error("Error:", error);
		alert("Error recruiting army: " + error.message);
	});
}  


