import { supabase } from '../supabaseClient.js';
import { showSettlementRadialMenu } from './popup.js';

// Grab your outliner containers
var outliner = document.getElementById('outliner');
const settlementArmiesContainer = document.getElementById('settlement_armies');
const nationArmies = document.getElementById('nation_armies');
const nationArmiesContainer = document.getElementById('nation_armies_container');
const settlementMoney = document.getElementById('outliner_settlement_money_value');
const settlementManpower = document.getElementById('outliner_settlement_manpower_value');
const nationMoneyLabel = document.getElementById('outliner_nation_money');
const nationMoney = document.getElementById('outliner_nation_money_value');
const nationManpowerLabel = document.getElementById('outliner_nation_manpower');
const nationManpower = document.getElementById('outliner_nation_manpower_value');


//indicator containers
const settlement_money_indicator = document.getElementById('outliner_settlement_money_indicator');
const nation_money_indicator = document.getElementById('outliner_nation_money_indicator');

export async function loadOutliner() {



	outliner.classList.remove('hidden');

	// Clear any existing content
	settlementArmiesContainer.innerHTML = '';
	nationArmies.innerHTML = '';

	const currentUser = window.currentUser;

	console.log("Current user: ", currentUser);

	const settlement = Object.values(window.map.settlements).find(settlement => settlement.user_id === currentUser.id) ?? null;

	if (!settlement) {
		console.error('No settlement found for current user');
		hideNationElements();
		return;
	}

	const province = Object.values(window.map.provinces).find(province => province.id === settlement.province_id);

	settlementMoney.textContent = settlement.money;
	settlementManpower.textContent = settlement.manpower;
	nationMoney.textContent = province.nation_money;
	nationManpower.textContent = province.nation_manpower;

	const { data: { session } } = await supabase.auth.getSession();
	const accessToken = session.access_token;

	var settlement_income = null;
	var nation_income = null;

	if (currentUser.id !== province.nation_user_id || province.nation_user_id === null) {
		hideNationElements();
	}
	
	fetch('/api/get_income', {
		method: 'POST',
		headers: {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${accessToken}`
		},
		body: JSON.stringify({ type: 'settlement', id: settlement.id })
	})
	.then(response => response.json())
	.then(async data => {
		if (data.income) {
		console.log("Settlement income: " + data.income.netMoneyIncome);
		settlement_income = data.income.netMoneyIncome;

		if (settlement_income > 0) {
			settlement_money_indicator.src = 'green_up_arrow.png';
			// settlement_money_indicator.style.display = 'inline';
		} else {
			settlement_money_indicator.src = 'red_down_arrow.png';
			// settlement_money_indicator.style.display = 'inline';
		}
	
		} else {
		console.log("No income found");
		}
	})
	.catch(error => {
		console.error("Error:", error);
		alert("Error updating settlement loyalty: " + error.message);
	});

	fetch('/api/get_income', {
		method: 'POST',
		headers: {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${accessToken}`
		},
		body: JSON.stringify({ type: 'nation', id: province.nation_id })
	})
	.then(response => response.json())
	.then(async data => {
		if (data.income) {
		nation_income = data.income.netMoneyIncome;
		console.log("Nation income: " + nation_income);

		if (nation_income > 0) {
			nation_money_indicator.src = 'green_up_arrow.png';
			// nation_money_indicator.style.display = 'inline';
		} else {
			nation_money_indicator.src = 'red_down_arrow.png';
			// nation_money_indicator.style.display = 'inline';
		}
	
		} else {
		console.log("No income found");
		}
	})
	.catch(error => {
		console.error("Error:", error);
		alert("Error updating settlement loyalty: " + error.message);
	});


	// Make sure window.map.armies exists and is an array
	if (!window.map?.armies) {
		console.error('No armies found in window.map.armies');
		return;
	}

	// Loop through the object with a for...in
	for (let armyId in window.map.armies) {

		let location_text;

		// Optional safety check to ensure we're only iterating over own properties
		if (!window.map.armies.hasOwnProperty(armyId)) {
			continue;
		}

		const army = window.map.armies[armyId];
		var destinationIcon = null;
		destinationIcon = document.createElement('img');

		// Check if this army belongs to the signed-in user
		if (!window.currentUser) return;
		if (army.user_id === window.currentUser.id) {
			
			if (army.location_type === 'settlement') {
				location_text = `${window.map.settlements[army.location].name}`;
			}
			else {
				location_text = `Province ${army.location}`;
			}

			if (army.destination) {				
				destinationIcon.src = 'moving.png';
				destinationIcon.width = 16;
				destinationIcon.height = 16;
				destinationIcon.style.marginLeft = '10px';
			}

			// Create a new div element to represent the army
			const armyDiv = document.createElement('div');			
			armyDiv.textContent = `${army.size}K | ${location_text}`;
			armyDiv.classList.add('outliner-army-item');

			armyDiv.setAttribute("data-army-size", army.size.toString());

				armyDiv.addEventListener('click', () => {
					window.map.mapSelectedArmyId = army.id;
					window.map.focusArmy(army.id);
				});

			armyDiv.appendChild(destinationIcon);

			// Check allegiance to decide where to display the army
			if (army.allegiance === 'settlement') {
				insertArmyInDescendingOrder(settlementArmiesContainer, armyDiv, army.size);
			} else if (army.allegiance === 'nation') {
				insertArmyInDescendingOrder(nationArmies, armyDiv, army.size);
			}
		}
	}
}

if (window.currentUser) {
	  loadOutliner();
}

// Helper function to insert the new armyDiv in descending order.
function insertArmyInDescendingOrder(container, armyDiv, size) {
	// Go through existing child nodes in the container.
	const children = container.children;
	
	for (let i = 0; i < children.length; i++) {
	  // We need a reliable way to get the size from the existing child.
	  // For example, you might store it in a data attribute:
	  const currentSize = parseInt(children[i].getAttribute("data-army-size"), 10);
  
	  // If the new army is larger, insert it before this child.
	  if (size > currentSize) {
		container.insertBefore(armyDiv, children[i]);
		return;
	  }
	}
	// If we didn't insert it (means it’s smaller than all), just append at the end.
	container.appendChild(armyDiv);
  }

document.addEventListener('DOMContentLoaded', () => {
  const outliner = document.getElementById('outliner');
  const toggleButton = document.getElementById('outliner-toggle-button');
  const settlementButton = document.getElementById('settlement_button');

  toggleButton.addEventListener('click', () => {
    outliner.classList.toggle('outliner-collapsed');
  });

  settlementButton.addEventListener('click', () => {
	//get settlement_id of the settlement with the current user id
	const settlement_id = Object.keys(window.map.settlements).find(key => window.map.settlements[key].user_id === window.currentUser.id);
	window.map.focusSettlement(settlement_id);

	//get feature coordinate of settlement from settlementsLayer
	const feature = window.map.settlementsLayer.getSource().getFeatures().find((f) => f.get('id') == settlement_id);
	const coordinate = feature.getGeometry().getCoordinates();

	window.map.settlementRadialMenuOverlay.setPosition(coordinate);
	showSettlementRadialMenu();
	
	window.map.mapSelectedSettlementId = settlement_id;
  });


});

outliner = document.getElementById('outliner');
if (window.innerWidth < 480) {
	outliner.classList.add('outliner-collapsed');
} else {
	outliner.classList.remove('outliner-collapsed');
}

//if screen size is less than 800px, collapse the outliner
window.addEventListener('resize', () => {
	if (window.innerWidth < 480) {
	outliner.classList.add('outliner-collapsed');
} else {
	outliner.classList.remove('outliner-collapsed');
}
});


function hideNationElements() {
	nationMoney.style.display = 'none';
	nationManpower.style.display = 'none';
	nationMoneyLabel.style.display = 'none';
	nationManpowerLabel.style.display = 'none';
	nation_money_indicator.style.display = 'none';
	nationArmiesContainer.style.display = 'none';
}
