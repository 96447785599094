import MapObj from './src/js/classes/map/mapobj.js'
import './src/js/index.js'
import { supabase } from './src/js/supabaseClient.js';
import {getUser} from './src/js/index.js';
import { loadOutliner } from './src/js/ui/outliner.js';
import { initChat } from './src/js/ui/chat.js';


const map = new MapObj()


export async function loadMapData() {

  console.log("Loading map data");

  await Promise.all([
    getUser(),
    await map.loadProvinces(),
    map.loadSettlements(),
    map.loadArmies()
  ]).then(() => {
    if (window.currentUser) {
      loadOutliner()
      initChat()
    }
  });

  await map.onViewChangeResolution()

  console.log("Map data loaded");

}

document.addEventListener('visibilitychange', function() {
  if (document.visibilityState === 'hidden') {
    // Tab is inactive
  } else {
    loadWithOverlay();
  }
});

// Load map data test

function loadWithOverlay() {
  const loadingOverlay = document.getElementById('loading-overlay');

  Promise.all([
    loadingOverlay.classList.remove('hidden'),
    loadingOverlay.style.display = 'flex',
    loadMapData()
  ]).then(() => {
    // Trigger the fade-out by adding the 'hidden' class
    loadingOverlay.classList.add('hidden');
    
    // Remove the overlay entirely after the transition (optional)
    setTimeout(() => {
      loadingOverlay.style.display = 'none';
    }, 1000); // Match this time with the transition duration
  });
}

loadWithOverlay();


if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then(() => void(0))
    .catch((err) => console.error('Service Worker registration failed:', err));
}

const vapidPublicKey = 'BF_fp9y6rTetabQcU_yWWk9RS3r3KpvoYKcd62P0dLxa2kt1kfalwkbD0UVJd3-IIHMnxVEO5w6rc08ZDlW2qC0';

// Request permission, then create a subscription
async function subscribeToPush() {
  if (!('serviceWorker' in navigator)) {
    console.warn('Service Worker not supported in this browser.');
    return;
  }

  const swReg = await navigator.serviceWorker.ready; // Wait until SW is ready
  const subscription = await swReg.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
  });

  const subscriptionObject = subscription.toJSON();
  const endpoint = subscriptionObject.endpoint;


  // Send `subscription` to your server to store in Supabase
  const { error } = await supabase
  .from('push_subscriptions')
  .upsert({
    endpoint,
    subscription: subscriptionObject,
  },
  {
    onConflict: 'endpoint' // name of the unique column
  });

  if (error) {
    console.error('Failed to store push subscription:', error);
  } else {
    void(0);
  }
}

// Convert VAPID key from URL-safe base64 to Uint8Array
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

async function unsubscribeFromPush() {
  if (!('serviceWorker' in navigator)) {
    console.warn('Service Worker not supported in this browser.');
    return;
  }

  const swReg = await navigator.serviceWorker.ready; // Ensure Service Worker is ready
  const subscription = await swReg.pushManager.getSubscription();

  if (subscription) {
    const subscriptionObject = subscription.toJSON();
    const endpoint = subscriptionObject.endpoint;

    // Remove the subscription from the Supabase database
    const { error } = await supabase
      .from('push_subscriptions')
      .delete()
      .eq('endpoint', endpoint); // Use the endpoint as the unique identifier

    if (error) {
      console.error('Failed to unsubscribe from push notifications in the database:', error);
    } else {
      console.log('Unsubscribed successfully from the database.');
    }

    // Unsubscribe from push manager
    await subscription.unsubscribe();
  } else {
    console.warn('No active push subscription found.');
  }
}

async function initializeNotificationIcons() {
  if (!('serviceWorker' in navigator)) {
    console.warn('Service Worker not supported in this browser.');
    return;
  }

  const swReg = await navigator.serviceWorker.ready;
  const subscription = await swReg.pushManager.getSubscription();

  const notificationsOnIcon = document.getElementById('notications-on');
  const notificationsOffIcon = document.getElementById('notifications-off');

  if (subscription) {
    // If a subscription exists, show the "notifications-on" icon
    notificationsOnIcon.style.display = 'inline';
    notificationsOnIcon.style.visibility = 'visible';
    notificationsOffIcon.style.display = 'none';
    notificationsOffIcon.style.visibility = 'hidden';
  } else {
    // If no subscription exists, show the "notifications-off" icon
    notificationsOffIcon.style.display = 'inline';
    notificationsOffIcon.style.visibility = 'visible';
    notificationsOnIcon.style.display = 'none';
    notificationsOnIcon.style.visibility = 'hidden';
  }
}


// Notifications toggle functionality
const notificationsOnIcon = document.getElementById('notications-on');
const notificationsOffIcon = document.getElementById('notifications-off');

notificationsOnIcon.addEventListener('click', async () => {
  await unsubscribeFromPush();
  notificationsOnIcon.style.display = 'none';
  notificationsOffIcon.style.display = 'inline';
  notificationsOffIcon.style.visibility = 'visible';
});

notificationsOffIcon.addEventListener('click', async () => {
  await subscribeToPush();
  notificationsOffIcon.style.display = 'none';
  notificationsOnIcon.style.display = 'inline';
  notificationsOnIcon.style.visibility = 'visible';
});

initializeNotificationIcons();

window.map = map;
