import { set } from "ol/transform.js";
import { panelShowInfo } from "../index.js";
import { closePopUp } from "./popup.js";
import { supabase } from '../supabaseClient.js';

// Right panel for provinces/settlement
const provinceButton = document.getElementById('province-button');
const settlementButton = document.getElementById('settlement-button');
const rightSlidingPanel = document.getElementById('right-sliding-panel');

const loyaltyStatus = document.getElementById('rp-settlement-loyalty');

const loyaltyToggle = document.getElementById('rp-settlement-loyalty-toggle');
const loyaltyTextElement = document.getElementById('rp-settlement-loyalty-text');


const rightPanelMergeBtnSettlement = document.getElementById('rp-army-merge-btn');
const rightPanelMergeBtnProvince = document.getElementById('rp-province-army-merge-btn');

const rightPanelSplitContSettlement = document.getElementById('settlement-split-off-container');
const rightPanelSplitContProvince = document.getElementById('province-split-off-container');
const splitButtonSettlement = document.getElementById('rp-army-split-btn');
const splitButtonProvince = document.getElementById('rp-province-army-split-btn');

const rightPanelRecruitContSettlement = document.getElementById('settlement-recruit-container');
const rightPanelRecruitBtnSettlement = document.getElementById('rp-army-recruit-btn');

const rightPanelRecruitContProvince = document.getElementById('province-recruit-container');
const rightPanelRecruitBtnProvince = document.getElementById('rp-province-army-recruit-btn');

// Touch variables
let rightPanelStartX = 0;
let rightPanelCurrentX = 0;
let rightPanelTouching = false;
let rightPanelTap = false;

let currentSettlementId = null;
let currentProvinceId = null;

let selectedArmiesSet = new Set();
let selected_location = null;

const rightPanelCloseButton = document.getElementById('right-panel-close-button');
// rightPanelCloseButton.addEventListener('click', closeRightPanel);
// Add touch event listeners
// rightSlidingPanel.addEventListener('touchstart', onRightPanelTouchStart);
// rightSlidingPanel.addEventListener('touchmove', onRightPanelTouchMove);
// rightSlidingPanel.addEventListener('touchend', onRightPanelTouchEnd);

function onRightPanelTouchStart(e) {
	if (e.target.closest('button, input, select, textarea, li')) {
		// Touch started on an interactive element, ignore
		return;
	}
	rightPanelStartX = e.touches[0].clientX;
	rightPanelCurrentX = 0;
	rightPanelTouching = true;
	rightPanelTap = true;
  }

  function onRightPanelTouchMove(e) {
	if (!rightPanelTouching) return;
	rightPanelTap = false;
	rightPanelCurrentX = e.touches[0].clientX;
	const translateX = Math.max(0, rightPanelCurrentX - rightPanelStartX);
	rightSlidingPanel.style.transform = `translateX(${translateX}px)`;
  }

  function onRightPanelTouchEnd(e) {
	if (!rightPanelTouching) return;
	rightPanelTouching = false;
	const swipeDistance = rightPanelCurrentX - rightPanelStartX;
	console.log(swipeDistance);
	if (swipeDistance > 50 || rightPanelTap) {
	  // User swiped right enough to close the panel
	  closeRightPanel();
	} else {
	  // Reset the panel position
	  rightSlidingPanel.style.transform = '';
	}
  }
  
  

  provinceButton.addEventListener('click', function () {
	rightSlidingPanel.classList.toggle('open');
	rightSlidingPanel.style.transform = ''; // Reset transform when opening
	panelShowInfo(rightSlidingPanel, 'province');
	closePopUp();
  });

  settlementButton.addEventListener('click', function () {
	rightSlidingPanel.classList.toggle('open');
	rightSlidingPanel.style.transform = ''; // Reset transform when opening
	panelShowInfo(rightSlidingPanel, 'settlement');
	closePopUp();
  });

function loadBattleHistory(obj) {
	const battleHistoryList = document.getElementById('rp-province-battle-history');
	battleHistoryList.innerHTML = '';

	obj.battle_history.forEach(battle => {

		battle.created_at += ' UTC';

		var local_time = new Date(battle.created_at).toLocaleString();

		const battleItem = document.createElement('li');
		battleItem.classList.add('battle-item');

		const battleInfo = document.createElement('div');
		battleInfo.innerHTML = `
		<strong>${battle.winner_nation_name}</strong> - ${local_time}<br>
		Province: ${battle.province}<br>
		${battle.nationA_name} Army Size: ${battle.groupA_total_size_before}<br>
		${battle.nationB_name} Army Size: ${battle.groupB_total_size_before}<br>
		${battle.nationA_name} Losses: ${battle.groupA_losses}<br>
		${battle.nationB_name} Losses: ${battle.groupB_losses}
		`;

		battleItem.appendChild(battleInfo);
		battleHistoryList.appendChild(battleItem);
	});
}
  

export function rightPanelSetInfo(type, obj, selectedArmies = []) {
	if (type == 'settlement') {
	  // Set the loyalty toggle state
	  loyaltyToggle.checked = obj.loyal;
	  loyaltyTextElement.innerHTML = loyaltyToggle.checked ? 'Loyal' : 'Disloyal';
	  selected_location = obj.id;
  
	  // Show or hide the loyalty toggle based on ownership
	  if (window.currentUser.id == obj.user_id && obj.nation_user_id != window.currentUser.id) {
		document.querySelector('.settlement-loyalty').style.display = 'flex';
		// Remove existing event listener
		loyaltyToggle.removeEventListener('change', loyaltyToggleHandler);
		// Add event listener for loyalty toggle
		loyaltyToggle.addEventListener('change', loyaltyToggleHandler);
	  } else {
		document.querySelector('.settlement-loyalty').style.display = 'none';
	  }
  
	  window.map.RightPanel = 'settlement';
	  currentSettlementId = obj.id;
  
	  document.getElementById('rp-settlement-name').innerHTML = obj.name;
	  document.getElementById('rp-settlement-owner').innerHTML = obj.user_display_name;
	  document.getElementById('rp-settlement-manpower').innerHTML = obj.manpower;
	  document.getElementById('rp-settlement-money').innerHTML = obj.money;
  
	  rightPanelMergeBtnSettlement.style.display = 'none';
	  rightPanelSplitContSettlement.style.display = 'none';
	  rightPanelRecruitContSettlement.style.display = 'none';

	  // Compute income based on settlement loyalty
	  let moneyIncome = (obj.loyal && obj.user_id != obj.nation_user_id) ? 10 : 20;
	  let manpowerIncome = (obj.loyal && obj.user_id != obj.nation_user_id) ? 100 : 200;
  
	  // Compute expenses from all armies belonging to the user with allegiance 'settlement'
	  let expensesFromArmies = 0;
	  const armies = window.map.armies;
	  for (let armyId in armies) {
		let army = armies[armyId];
		if (army.user_id == obj.user_id && army.allegiance == 'settlement') {
		  expensesFromArmies += army.size * 5; // ARMY_MAINTENANCE_COST_PER_UNIT = 5
		}
	  }
  
	  // Update the HTML elements
	  document.getElementById('rp-settlement-money-income').innerText = moneyIncome;
	  document.getElementById('rp-settlement-money-expenses').innerText = expensesFromArmies;
	  document.getElementById('rp-settlement-manpower-income').innerText = manpowerIncome;
  
	  // Pass selectedArmies to loadArmies
	  loadArmies(obj, selectedArmies);
	}
  
	if (type == 'province') {
	  selected_location = obj.id;
	  window.map.RightPanel = 'province';
	  currentProvinceId = obj.id;
  
	  document.getElementById('rp-province-owner').innerHTML = obj.nation_name;
	  document.getElementById('rp-province-manpower').innerHTML = obj.nation_manpower;

	  loadBattleHistory(obj);
  
	  rightPanelSplitContProvince.style.display = 'none';
	  rightPanelMergeBtnProvince.style.display = 'none';
	  //rightPanelRecruitContProvince.style.display = 'none';
  
	  // Pass selectedArmies to loadArmies
	  loadArmies(obj, selectedArmies);
	}
  }
  

function loyaltyToggleHandler() {
	const newLoyaltyStatus = loyaltyToggle.checked;
	// Update the loyalty status text inside the slider
	loyaltyTextElement.innerHTML = newLoyaltyStatus ? 'Loyal' : 'Disloyal';
	// Send the update to the server
	updateSettlementLoyalty(currentSettlementId, newLoyaltyStatus);
}
  

async function updateSettlementLoyalty(settlementId, isLoyal) {
	const { data: { session } } = await supabase.auth.getSession();
	const accessToken = session.access_token;
  
	fetch('/api/update_settlement_loyalty', {
	  method: 'POST',
	  headers: {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${accessToken}`
	  },
	  body: JSON.stringify({ settlement_id: settlementId, loyal: isLoyal })
	})
	.then(response => response.json())
	.then(async data => {
	  if (data.message == 'success') {
		console.log("Settlement loyalty updated successfully");
	
	  } else {
		const newLoyaltyStatus = !loyaltyToggle.checked;

		// Update the loyalty status text inside the slider
		loyaltyTextElement.innerHTML = newLoyaltyStatus ? 'Loyal' : 'Disloyal';
		loyaltyToggle.checked = newLoyaltyStatus;

		console.error("Error updating settlement loyalty:", data.error);
		alert("Error updating settlement loyalty: " + data.error);
	  }
	})
	.catch(error => {
	  console.error("Error:", error);
	  alert("Error updating settlement loyalty: " + error.message);
	});
}
  
  
  

export function closeRightPanel() {
	rightSlidingPanel.classList.remove('open');
	rightSlidingPanel.style.transform = ''; // Reset transform when closing
	// Clear movement arrows
	//window.map.movementArrowsLayer.getSource().clear();
  }

export async function mergeArmies(armies) {
	const armyIds = Array.from(armies).map(army => army.id);
	let army = armies.values().next().value;


	const { data: { session } } = await supabase.auth.getSession();
	const accessToken = session.access_token;

	if (armyIds.length > 1) {
		fetch('/api/merge_armies', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({ army_ids: armyIds })
		})
		.then(response => response.json())
			.then(async data => {
				if (data.message == 'success') {
					console.log("Army merged successfully");

					await window.map.loadArmies();

					//console.log(army);

					if (army.location_type == 'settlement') {
						loadArmies(window.map.settlements[army.location]);
					}
					else {
						loadArmies(window.map.provinces[army.location]);
					}

				}
			});
	}
}

export async function splitArmy(army, splitSize) {
	const { data: { session } } = await supabase.auth.getSession();
	const accessToken = session.access_token;

	console.log("entering splitArmy function");
  
	fetch('/api/split_army', {
	  method: 'POST',
	  headers: {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${accessToken}`
	  },
	  body: JSON.stringify({ army_id: army.id, split_size: splitSize })
	})
	.then(response => response.json())
	.then(async data => {
	  if (data.message == 'success') {
		console.log("Army split successfully");
  
		await window.map.loadArmies();
  
		if (army.location_type == 'settlement') {
		  loadArmies(window.map.settlements[army.location]);
		} else {
		  loadArmies(window.map.provinces[army.location]);
		}
	  } else {
		console.error("Error splitting army:", data.error);
	  }
	});
  }

  async function recruitArmy(locationId, locationType, recruitNumber) {
	console.log("Recruit army function called");
    const { data: { session } } = await supabase.auth.getSession();
    const accessToken = session.access_token;
    fetch('/api/recruit_army', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ location_id: locationId, location_type: locationType, recruit_size: recruitNumber })
    })
    .then(response => response.json())
    .then(async data => {
        if (data.message == 'success') {
            console.log("Army recruited successfully");
            await window.map.loadArmies();
			if (locationType == 'settlement') {
				document.getElementById('rp-settlement-manpower').innerHTML = data.updated_manpower;
            	loadArmies(window.map.settlements[locationId]);
			}
			else {
				//document.getElementById('lp-nation-manpower').innerHTML = data.updated_manpower;
				window.map.loadProvinces();
				loadArmies(window.map.provinces[locationId]);
			}
        } else {
            console.error("Error recruiting army:", data.error);
            alert("Error recruiting army: " + data.error);
        }
    })
    .catch(error => {
        console.error("Error:", error);
        alert("Error recruiting army: " + error.message);
    });
}  

async function recruitButtonHandler() {
    var recruitNumber = parseInt(document.getElementById('rp-settlement-recruit-number').value);
    await recruitArmy(currentSettlementId, 'settlement', recruitNumber);
}

async function recruitButtonProvinceHandler() {
    var recruitNumber = parseInt(document.getElementById('rp-province-recruit-number').value);
    await recruitArmy(currentProvinceId, 'province', recruitNumber);
}

async function splitButtonHandler() {
	var splitSize = parseInt(document.getElementById('rp-settlement-split-off-number').value);
	if (selectedArmiesSet.size !== 1) {
		alert("Please select exactly one army to split.");
		return;
	}
	if (isNaN(splitSize) || splitSize <= 0) {
		alert("Please enter a valid split size.");
		return;
	}
	const army = selectedArmiesSet.values().next().value;
	if (splitSize >= army.size) {
		alert("Split size must be less than army size.");
		return;
	}
	await splitArmy(army, splitSize);
}

async function splitButtonProvinceHandler() {
	console.log("Split button clicked");
	const splitSize = parseInt(document.getElementById('rp-province-split-off-number').value);
	if (selectedArmiesSet.size !== 1) {
		alert("Please select exactly one army to split.");
		return;
	}
	if (isNaN(splitSize) || splitSize <= 0) {
		alert("Please enter a valid split size.");
		return;
	}
	const army = selectedArmiesSet.values().next().value;
	if (splitSize >= army.size) {
		alert("Split size must be less than army size.");
		return;
	}
	await splitArmy(army, splitSize);
}

export async function loadArmies(obj, selectedArmies = [], movedArmies = false) {
	const armies = window.map.armies;
	const armyList = document.getElementById('army-list');
	const provinceArmyList = document.getElementById('rp-province-army-list');

	//user clicked a different location before the armies could load
	if (obj.id != selected_location) {
		return;
	}
  
	armyList.innerHTML = '';
	provinceArmyList.innerHTML = '';

	console.log(window.currentUser.id)
	console.log(obj.nation_user_id)
  
	if (window.currentUser.id == obj.user_id && obj.location_type == 'settlement') {
	  rightPanelRecruitContSettlement.style.display = 'flex';
	}
	else if (window.currentUser.id == obj.nation_user_id && obj.location_type == 'province') {
	  rightPanelRecruitContProvince.style.display = 'flex';
	}
	else {
		rightPanelRecruitContProvince.style.display = 'none';
		rightPanelRecruitContSettlement.style.display = 'none';
	}

  
	// Clear selectedArmiesSet before populating if movedArmies is false
	if (!movedArmies) {
		selectedArmiesSet.clear();
		window.map.movementArrowsLayer.getSource().clear();
	}
	
  
	// Create a set of selected army IDs for easy lookup
	const selectedArmyIds = new Set(selectedArmies.map(army => army.id));
  
	for (let armyId in armies) {
	  let army = armies[armyId];
	  if (army.location == obj.id && army.location_type == obj.location_type) {
		const armyItem = document.createElement('li');
		armyItem.classList.add('army-item');
  
		const armyColorFlag = document.createElement('div');
  
		if (army.loyal) {
		  armyColorFlag.classList.add('army-color-flag');
		  armyColorFlag.style.backgroundColor = army.nation_color;
		}
		else if (army.loyal == null) {
		  armyColorFlag.classList.add('army-color-flag');
		  armyColorFlag.style.backgroundColor = "yellow"
		}
		else {
		  armyColorFlag.classList.add('army-color-flag-rebel');
		  armyColorFlag.style.backgroundColor = "red"
		}
  
		const armyInfoContainer = document.createElement('div');
		armyInfoContainer.classList.add('army-info-container');
  
		const armyText = document.createElement('div');
		armyText.innerHTML = `${army.size}K Infantry`;
  
		const armyDestination = document.createElement('div');
		armyDestination.classList.add('army-destination');
  
		armyInfoContainer.appendChild(armyText);
  
		if (army.destination && army.destination_type) {
			if (army.allegiance == 'settlement') {
		  		armyDestination.innerHTML = `${army.settlement_name} - Moving to ${army.destination_type} ${army.destination}`;
			}
			else {
				armyDestination.innerHTML = `${army.nation_name} - Moving to ${army.destination_type} ${army.destination}`;
			}
		}
		else {
			if (army.allegiance == 'settlement') {
		  		armyDestination.innerHTML = `${army.settlement_name} - Idle`;
			}
			else {
				armyDestination.innerHTML = `${army.nation_name} - Idle`;
			}
		}
  
		armyInfoContainer.appendChild(armyDestination);
  
		armyItem.appendChild(armyColorFlag);
		armyItem.appendChild(armyInfoContainer);
  
		if (window.currentUser.id == army.user_id) {
		  const armyButton = document.createElement('button');
		  armyButton.classList.add('side-panel-button');
		  armyButton.innerHTML = 'Move';
		  armyButton.addEventListener('click', function () {
			window.map.enterMoveMode(army);
			closeRightPanel();
			closePopUp();
		  });
		  armyItem.appendChild(armyButton);

		  const disbandButton = document.createElement('button');
		  disbandButton.classList.add('right-panel-button-disband');
		  disbandButton.innerHTML = 'X';
		  disbandButton.addEventListener('click', function () {			
			army.disband();
		  });
		  armyItem.appendChild(disbandButton);

		  rightPanelMergeBtnProvince.style.display = 'block';
		  rightPanelMergeBtnSettlement.style.display = 'block';
		  rightPanelSplitContProvince.style.display = 'flex';
		  rightPanelSplitContSettlement.style.display = 'flex';
		}
  
		// Add click event listener for selecting and deselecting army items
		armyItem.addEventListener('click', function () {
		  
  
		  if (selectedArmiesSet.has(army)) {
			selectedArmiesSet.delete(army);
			armyItem.classList.remove('selected');

			// Remove movement arrow for the deselected army
			var army_arrow = window.map.movementArrowsLayer.getSource().getFeatures().find(f => f.get('army_id') == army.id);
			window.map.movementArrowsLayer.getSource().removeFeature(army_arrow);
		  } else {
			if (army.user_id == window.currentUser.id) {
				selectedArmiesSet.add(army);
				armyItem.classList.add('selected');
				// Draw movement arrow for the selected army if it's moving
				window.map.drawMovementArrow(army);
			}
		  }
		});
  
		// Automatically select armies if they are in selectedArmies
		if (selectedArmyIds.has(army.id)) {
		  selectedArmiesSet.add(army);
		  armyItem.classList.add('selected');
		  // Draw movement arrow for the selected army if it's moving
		  window.map.drawMovementArrow(army);
		}
  
		if (army.location_type == 'settlement') {
		  armyList.appendChild(armyItem);
		}
		else if (army.location_type == 'province') {
		  provinceArmyList.appendChild(armyItem);
		}
	  }
	}

	rightPanelMergeBtnSettlement.addEventListener('click', async function () {
		await mergeArmies(selectedArmiesSet);
	});

	rightPanelMergeBtnProvince.addEventListener('click', async function () {
		await mergeArmies(selectedArmiesSet);
	});

	rightPanelRecruitBtnSettlement.removeEventListener('click', recruitButtonHandler);
	rightPanelRecruitBtnSettlement.addEventListener('click', recruitButtonHandler);

	splitButtonSettlement.removeEventListener('click', splitButtonHandler);
	splitButtonSettlement.addEventListener('click', splitButtonHandler);

	rightPanelRecruitBtnProvince.removeEventListener('click', recruitButtonProvinceHandler);
	rightPanelRecruitBtnProvince.addEventListener('click', recruitButtonProvinceHandler);

	splitButtonProvince.removeEventListener('click', splitButtonProvinceHandler);
	splitButtonProvince.addEventListener('click', splitButtonProvinceHandler);
}
