import { fromLonLat, toLonLat } from 'ol/proj.js';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import Style from 'ol/style/Style.js';
import Icon from 'ol/style/Icon.js';
import Stroke from 'ol/style/Stroke.js';
import Fill from 'ol/style/Fill.js';

export default class Province {
	constructor(data, mapObj) {
		this.mapObj = mapObj
		this.id = data.id;
		this.nation_id = data.nation_id;
		this.borderProvinces = data.border_provinces;
		this.location_type = 'province';
		this.color = data.color;
		this.nation_name = data.nation_name;
		this.nation_manpower = data.nation_manpower;
		this.nation_money = data.nation_money;
		this.nation_user = data.nation_user;
		this.nation_user_id = data.nation_user_id;
		this.battle_history = data.battle_history;
	}
	loadProvinceData() {
		const vectorLayer = this.mapObj.provincesLayer;
		const feature = vectorLayer.getSource().getFeatures().find(feature => feature.get('id') == this.id);
		if (feature && this.color) {
			feature.set('obj', this);

			const style = new Style({
				fill: new Fill({
					color: this.color,
				}),
				stroke: new Stroke({
					color: '#404242',
					width: 1.5,
				}),
				ZIndex: 0,
			});
			feature.setStyle(style);
		}
	}

}