import { fromLonLat, toLonLat } from 'ol/proj.js';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import Style from 'ol/style/Style.js';
import Icon from 'ol/style/Icon.js';
import Stroke from 'ol/style/Stroke.js';
import Fill from 'ol/style/Fill.js';

export default class Settlement {
	constructor(data, mapObj) {
		this.id = data.id;
		this.name = data.name;
		this.user_id = data.user_id;
		this.coords = data.coords.split(',').map(Number);
		this.manpower = data.manpower;
		this.money = data.money;
		this.user_display_name = data.user_display_name;
		this.province_id = data.province_id;
		this.loyal = data.loyal;
		this.nation_id = data.nation_id;
		this.nation_user_id = data.nation_user_id;
		this.location_type = 'settlement';
		this.nation_province_count = data.nation_province_count;
		this.mapObj = mapObj

	}
	drawIcon() {
		// Convert the settlement's coordinates to the map's projection
		const transformedCoordinates = fromLonLat(this.coords);

		// Create a new feature for the icon
		const iconFeature = new Feature({
			geometry: new Point(transformedCoordinates),
			id: this.id
		});

		if (this.loyal) {
			// Create a style for the icon
			iconFeature.setStyle(new Style({
				image: new Icon({
					//blue
					color: '#00FF00',
					src: 'dot.png', // URL to your icon image
					scale: 0.07, // Adjust the scale as needed
					zIndex: 99,
				}),
			}));
		}
		else {
			// Create a style for the icon
			iconFeature.setStyle(new Style({
				image: new Icon({
					//red
					color: '#FF0000',
					src: 'dot.png', // URL to your icon image
					scale: 0.07, // Adjust the scale as needed
					zIndex: 99,
				}),
			}));
		}


		iconFeature.set('obj', this);

		// Add the icon feature to the settlementsLayer's source
		this.mapObj.settlementsLayer.getSource().addFeature(iconFeature);
	}
}